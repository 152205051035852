<template>
	<div class="page-form">
		<div class="app-logo">
			<img class="logo" src="../../assets/images/logo-white.png">
		</div>
		<!--  产品 评论 信息 -->
		<div class="app-form" v-if="langInfo">
			<div class="form-title">
				{{langInfo.newLangInfo.formPage.title50}}
			</div>
			<div class="form-item">
				<div class="item-title">
					<img class="icon" src="../../assets/images/icons/shop.png">
					<div class="text">
						{{ langInfo.newLangInfo.questPage.title }}
					</div>
				</div>
				<div class="form-list">
					<app-checkbox v-for="(item,index) in orderPayList" :key="index"
						:text="item==='Offline'?langInfo.newLangInfo.questPage.offline:item=='Other'?langInfo.newLangInfo.questPage.other:item"
						:checked="index===orderPay" @click.native="handClick(index)" />
				</div>
				<div class="item-tips" v-if="platformTips">
					{{platformTips}}
				</div>
			</div>
			<div class="form-item" v-if="isUserOnline">
				<!-- 订单id -->
				<div class="item-title">
					<img class="icon" style="width: 18px;height: 20px;" src="../../assets/images/icons/book.png" />
					<div class="text">
						{{ langInfo.newLangInfo.questPage.orderid }}
					</div>
				</div>
				<div class="item-value">
					<input v-model="formData.order_id" class="value-input"
						:placeholder="langInfo.newLangInfo.registerPage.mandatory" @focus="orderTips = ''">
				</div>
				<div class="item-tips" v-if="orderTips">
					{{orderTips}}
				</div>
			</div>
			<!-- face -->
			<div class="form-item">
				<div class="item-title">
					<img class="icon" src="../../assets/images/icons/order.png" />
					<div class="text">
						{{ langInfo.newLangInfo.questPage.facebook }}
					</div>
				</div>
				<div class="item-value">
					<input v-model="formData.review_url" class="value-input"
						:placeholder="langInfo.newLangInfo.registerPage.mandatory" @focus="reviewTips = ''">
				</div>
				<div class="item-tips" v-if="reviewTips">
					{{reviewTips}}
				</div>
			</div>
			<!-- 截图 -->
			<div class="form-item" v-if="!isUserOnline">
				<div class="item-title">
					<img class="icon" style="width: 18px;height: 20px;" src="../../assets/images/icons/book.png">
					<div class="text">
						{{ langInfo.newLangInfo.questPage.upload }}
					</div>
				</div>
				<van-uploader v-model="fileList" class="item-upload" :max-size="5000 * 1024" :after-read="afterRead">
					<div class="upload-style">
						<img class="icon" src="../../assets/images/icons/upload.png">
						<div class="u-text">
							{{ langInfo.newLangInfo.questPage.upload }}
						</div>
					</div>
				</van-uploader>
				<div class="item-tips" v-if="fileTips">
					{{fileTips}}
				</div>
			</div>
		</div>

		<div class="form-buttons" v-if="langInfo">
			<div class="button-blue" @click="handSubmit">
				{{langInfo.newLangInfo.registerPage.submit}}
			</div>
		</div>

		<!--  审核 -->
		<van-popup v-model.sync="showMsg" v-if="langInfo" :close-on-click-overlay="false"
			:overlay-style="{background:'rgba(0,0,0,0.4)'}" :round="true">
			<div class="msg-box">
				<div class="msg-close" @click="$router.replace('/info')">
					<van-icon name="close" />
				</div>
				<div class="msg-logo">
					<img class="logo" src="../../assets/images/success.png">
				</div>
				<!-- 提交成功提示 -->
				<div class="msg-title">
					{{langInfo.newLangInfo.formPage.succTitle}}
				</div>
				<!-- 审核 -->
				<div class="msg-content">
					{{langInfo.newLangInfo.formPage.succText50}}
				</div>
				<div class="msg-line-btn" v-if="infoData.Nation =='TH'">
					<div style="margin-bottom: 4px;">ส่งข้อความไปที่ Line @simplusth , เพื่อตรวจสอบสถานะคูปอง</div>
					<a data-v-6fa6a74a="" href="https://lin.ee/eRm0Yr7"><img data-v-6fa6a74a="" src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png" alt="เพิ่มเพื่อน" height="36" border="0"></a> 
				</div>
				<!-- 代金卷 -->
				<!-- <div class="msg-content" style="margin-top: 12px;">
					The voucher will be sent via Store Messenger once approved.
				</div> -->
			</div>
		</van-popup>
		<login-model :show="showLogin" ismask @click="getLogin" />
		<app-loading :show="uploadLoading" />
	</div>
</template>

<script>
	import {
		List
	} from '../../util/en_config_new';
	import {
		langData
	} from '../../util/lang_config_new';
	import {
		Popup,
		Uploader,
		Icon,
		Toast
	} from 'vant';
	import appCheckbox from '../../components/app-checkbox/app-checkbox.vue';
	import loginModel from '../../components/login-model/login-model.vue';
	import appLoading from '../../components/app-loading/app-loading.vue';
	export default {
		components: {
			appCheckbox,
			[Popup.name]: Popup,
			[Uploader.name]: Uploader,
			[Icon.name]: Icon,
			loginModel,
			appLoading
		},
		data() {
			return {
				orderPay: -1,
				orderPayList: ['Shopee', 'Lazada', 'TikTok Shop', 'Offline', 'Other'],
				fileList: [],
				showMsg: false,
				showForm: false,
				showLogin: false,
				infoData: {},
				formData: {},
				langInfo: null,
				showDataInfo: null, //包含手机号校验正则
				isUserOnline: true,
				platformTips: '',
				fileTips: '',
				orderTips: '',
				reviewTips: '',
				uploadLoading:false,
			};
		},
		created() {
			this.init();
		},
		methods: {
			getLogin() {
				this.showLogin = false;
				this.init();
			},
			init() {
				console.log(this.infoData, '1111');
				if (!localStorage.getItem('NationName')) {
					this.$router.replace("/index");
					return;
				}
				if (localStorage.getItem('NationName')) {
					this.infoData.Nation = localStorage.getItem('NationName');
					if (this.infoData.Nation === 'ID') {
						this.orderPayList[3] = 'Tokopedia';
						this.orderPayList[4] = 'Offline';
						this.orderPayList[5] = 'Other';
						this.$forceUpdate();
					}
					this.langInfo = this.setLang();
				}
				if (!localStorage.getItem('LoginToken')) {
					this.showLogin = true;
					return;
				}
				if (localStorage.getItem('NationName') && localStorage.getItem('LoginInfo')) {
					this.infoData = JSON.parse(localStorage.getItem('LoginInfo'));
					this.infoData.Nation = localStorage.getItem('NationName');
					if (this.infoData.Nation === 'ID') {
						this.orderPayList[3] = 'Tokopedia';
						this.orderPayList[4] = 'Offline';
						this.orderPayList[5] = 'Other';
					}
					this.langInfo = this.setLang();
				} else {
					this.showLogin = true;
				}

				if (this.infoData.status === 'pending' || this.infoData.status === 'deny') {
					this.$router.replace("/info");
				}
			},
			afterRead(file) {
				this.fileTips = '';
				this.uploadLoading = true;
				// 上传图片到图片服务器
				let fd = new FormData();
				fd.append('file', file.file);
				this.axios.post(`/api/upload/file`, fd, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					}).then((res) => {
						this.uploadLoading = false;
						if (res.data.code == 200) {
							this.formData.review_filename = res.data.filename;
						} else if (res.data.code == 400) {
							this.showLogin = true;
						} else {
							this.formData.review_filename = '';
							this.fileTips = res.data.msg;
						}
					})
					.catch((err) => {
						this.uploading = false
					});
			},
			handSubmit() {

				// console.log(this.formData.review_url) //.includes('https://www.facebook.com/SimplusTH')
				// return;
				/**
				 * 平台
				 * */
				if (!this.formData.platform) {
					this.platformTips = this.langInfo.newLangInfo.registerPage.mandatory;
					return;
				}
				if (this.isUserOnline) {
					if (!this.formData.order_id) {
						this.orderTips = this.langInfo.newLangInfo.tipsInfo.order_error;
						return;
					}
				}
				if (!this.formData.review_url) {
					this.reviewTips = this.langInfo.newLangInfo.registerPage.mandatory;
					return;
				}
				if (this.infoData.Nation != 'TH') {
					if (!this.formData.review_url.includes('http')) {
						this.reviewTips = this.langInfo.newLangInfo.tipsInfo.http;
						return;
					}
				}

				if (!this.isUserOnline) {
					if (!this.formData.review_filename) {
						this.fileTips = this.langInfo.newLangInfo.questPage.upload;
						return;
					}
					delete this.formData.order_id;
				}
				if (!this.fileList.length || this.isUserOnline) {
					delete this.formData.review_filename;
				}
				this.formData.customer_id = this.infoData.User_id;
				this.formData.Nation = this.infoData.Nation;
				this.axios.post(`/api/addreview/`, this.formData).then((res) => {
						let resstr = res.data;
						if (resstr.code === 200) {
							this.showMsg = true;
						} else if (res.data.code == 400) {
							this.showLogin = true;
						} else {
							Toast(resstr.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			handClick(index) {
				this.orderPay = index;
				this.formData.platform = this.orderPayList[index] === 'Offline' ? 'Offline channels' : this.orderPayList[
					index].includes('TikTok') ? 'TikTok' : this.orderPayList[index];
				this.isUserOnline = ['Offline', 'Other'].includes(this.orderPayList[index]) ? false : true;
				this.platformTips = '';
			},
			setLang() {
				let Info = langData.filter((res) => res.Nation == this.infoData.Nation)[0];
				this.showDataInfo = List.filter((res) => res.Nation == this.infoData.Nation)[0];
				return Info == undefined ? langData[0] : Info;
			}
		}
	};
</script>

<style lang="less">
	.page-form {
		width: 100vw;
		height: 100vh;
		background: #4E5E9A;
		position: relative;

		.app-logo {
			padding: 45px 88px 54px;
			display: flex;
			justify-content: center;

			.logo {
				width: 150px;
				height: 48px;
			}
		}

		.app-form {
			width: calc(100vw - 48px);
			height: 80vh;
			border-radius: 24px 24px 0px 0px;
			background: #FFFFFF;
			padding: 24px 24px 160px;

			.form-title {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-bottom: 24px;
			}

			.form-item {
				display: flex;
				flex-direction: column;

				.item-title {
					display: flex;
					align-items: center;
					padding-bottom: 5px;
					padding-top: 5px;

					.icon {
						width: 18px;
						height: 18px;
					}

					.text {
						padding-left: 8px;
						color: #4D4D4D;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						padding: 4px;
					}
				}

				.item-value {
					border-radius: 8px;
					overflow: hidden;
					background: #F8F8F8;
					padding: 0px 24px;

					.value-input {
						width: 100%;
						height: 32px;
						line-height: 32px;
						background: #F8F8F8;
						border: 0px;
					}

					input {
						outline: none;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 500;
						color: #333333;
					}

					input::placeholder {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 500;
						color: #9FA0A2;
					}
				}

				.item-tips {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: red;
				}

				.item-upload {
					height: 66px;
					border-radius: 8px;
					background: #F8F8F8;
					overflow: hidden;

					.upload-style {
						display: flex;
						justify-content: center;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						height: 66px;

						.icon {
							width: 18px;
							height: 18px;
						}

						.u-text {
							//line-height: 0px;
							color: #C8C9CD;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
						}
					}

					.van-uploader__input-wrapper {
						width: 100%;
					}

					.van-uploader__upload {
						margin: 0;
						width: 100%;
						height: 66px;
						line-height: 66px;
					}

					.van-uploader__preview {
						margin: 0px;
						width: 100%;
						display: flex;
						justify-content: center;

						.van-uploader__preview-image {
							margin: 0px;
							width: 100%;
							height: 66px;
						}
					}
				}
			}
		}


		.form-buttons {
			width: calc(100vw - 48px);
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			bottom: 0px;
			padding: 18px 24px;
			background: #FFFFFF;
			z-index: 99;

			.button-blue {
				width: 80%;
				height: 36px;
				line-height: 36px;
				background: #4E5E9A;
				color: #ffffff;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 600;
				text-align: center;
				border-radius: 24px;
			}
		}

		.msg-box {
			width: 70vw;
			height: 310px;
			border-radius: 18px;
			padding: 20px 16px;

			.msg-close {
				text-align: right;
			}

			.msg-logo {
				text-align: center;

				.logo {
					width: 60px;
					height: 70px;
				}
			}

			.msg-title {
				
				font-size: 18px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 600;
				color: #245BD9;
				padding: 12px;
			}
			.msg-line-btn{
				text-align: center;
				padding: 6px 12px;
				
			}
			.msg-content {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 500;
				color: #323233;
			}
		}
	}
</style>
