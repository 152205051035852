<template>
	<div class="app-checkbox">
		<!-- 是否勾选了 -->
		<img class="checkbox" v-if="!checked" src="../../assets/images/checkbox.png">
		<img class="checkbox" v-else src="../../assets/images/checkbox-true.png">
		<div class="text">{{text}}</div>
	</div>
</template>

<script>
	export default {
		props: {
			text: {
				type: String,
				default: ''
			},
			checked: {
				type: Boolean,
				default: false
			}
		}
	}
</script>

<style lang="less">
	.app-checkbox {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		.checkbox {
			width: 18px;
			height: 18px;
		}

		.text {
			padding-left: 12px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 500;
			color: #292929;
			white-space: nowrap;
		}
	}
</style>
