<template>
  <div class="app-loading">
    <!-- 弹框  -->
    <van-popup
      :close-on-click-overlay="false"
      :round="false"
      :overlay="true"
      :overlay-style="{ background: 'rgba(255,255,255,0.3)' }"
      :style="{ background: 'rgba(255,255,255,0)', overflow: 'hidden' }"
      v-model="show"
    >
      <van-loading color="#1989fa" />
      <!-- <van-loading type="spinner" color="#1989fa" /> -->
    </van-popup>
  </div>
</template>

<script>
// import {
// 	List
// } from '../../util/en_config_new';
// import {
// 	langData
// } from '../../util/lang_config_new';
import { Popup, Loading } from "vant";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ismask: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [Popup.name]: Popup,
    [Loading.name]: Loading,
  },
};
</script>

<style lang="less" scoped>
.app-loading {
}
</style>
